
// @ is an alias to /sr
import { defineComponent, reactive, toRefs } from "vue";
import AfterLoginPageLayout from "@/views/layouts/AfterLoginPageLayout.vue";
import TextButton from "@/components/atomics/TextButton.vue";
export default defineComponent({
  name: "Invoice",
  components: {
    TextButton,
    AfterLoginPageLayout,
  },
  setup() {
    const state = reactive({
      isGoToInvoice: false,
    });

    return { ...toRefs(state) };
  },
  methods: {
    returnInvoiceList() {
      this.isGoToInvoice = true;
      this.$router.push("/invoice");
    },
  },
  beforeRouteLeave(to, from, next) {
    if (to.fullPath.includes("/invoice") && !this.isGoToInvoice) {
      next({ name: "Dashboard" });
    } else next();
  },
});
